import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { useStore } from "../../../../utils/store";
import {
  removeService,
  approveService,
} from "../../../../requests/service-request";
import { GetMonth, DayOfMonth, GetYear } from "../../../../utils/dateConverter";

import CarInfoModal from "./CarInfoModal";
import UserInfoModal from "./UserInfoModal";

import { ReactComponent as Accept } from "../../../../svgs/accept.svg";
import { ReactComponent as Trash } from "../../../../svgs/trash.svg";
import { ReactComponent as User } from "../../../../svgs/account.svg";
import { ReactComponent as Auto } from "../../../../svgs/garage.svg";

export default function Waiting() {
  const [{ history }, dispatch] = useStore();
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [filtered, setFiltered] = useState(undefined);
  const [carToShowDetails, setCarToShowDetails] = useState(null);
  const [userToShowDetails, setUserToShowDetails] = useState(null);
  const navigate = useNavigate();
  console.log({ userToShowDetails });
  useEffect(
    () =>
      setFiltered(
        history.filter((item) => !item.shopApproved && !item.archived)
      ),
    [history]
  );

  const mutationConfig = {
    retry: false,
    onMutate: () => {
      dispatch({ type: "onLoading", payload: "loading" });
    },
    onSettled: () => {
      dispatch({ type: "onLoading", payload: undefined });
    },
    onSuccess: (res, variables) => {
      const newHistory = [...history];
      const serviceIndex = newHistory.findIndex(
        (item) => item._id === variables.serviceID
      );
      if (variables.remove) {
        newHistory.splice(serviceIndex, 1);
      } else newHistory[serviceIndex] = res.data;

      dispatch({ type: "updateHistory", payload: newHistory });
      dispatch({ type: "onSuccess", payload: { visible: true } });
      setTimeout(() => {
        dispatch({ type: "onSuccess", payload: { visible: false } });
      }, 2000);
    },
    onError: (error) => {
      dispatch({ type: "onError", payload: error });
      navigate("/response");
    },
  };

  const { mutate: approveServiceMutation } = useMutation(
    approveService,
    mutationConfig
  );

  const { mutate: rejectMutate } = useMutation(removeService, mutationConfig);

  const handleItemApproval = (serviceID, approve = false) => {
    setShowActionButtons(false);
    if (approve) {
      approveServiceMutation({ serviceID });
    } else {
      rejectMutate({ serviceID, remove: true });
    }
  };
  return (
    <>
      {carToShowDetails && (
        <CarInfoModal
          {...carToShowDetails}
          handleClose={() => setCarToShowDetails(null)}
        />
      )}
      {userToShowDetails && (
        <UserInfoModal
          {...userToShowDetails}
          handleClose={() => setUserToShowDetails(null)}
        />
      )}
      <div className="service-history-list-container-new">
        {filtered === undefined ? null : filtered.length ? (
          filtered.map((item, index) => (
            <div className="service-history-item-container-new" key={index}>
              <div className="service-history-item-status-bar border-top-radius">
                <span className="status-desc">
                  {item.approved ? "w serwisie" : "w kolejce"}
                </span>
              </div>
              <div className="service-history-item-wrap">
                <div className="service-history-item-timestamp">
                  <span className="service-timestamp-date">
                    {DayOfMonth(item.createdAt)}
                  </span>
                  <span className="service-timestamp-month">
                    {GetMonth(item.createdAt)}
                  </span>
                  <span className="service-timestamp-year">
                    {GetYear(item.createdAt)}
                  </span>
                </div>
                <div className="service-history-item">
                  <div
                    className="service-item-user-wrapper"
                    onClick={() => setUserToShowDetails(item.user)}
                  >
                    <User />
                    <span>{item.user.firstname.toUpperCase()}</span>
                    <span>{item.user.lastname.toUpperCase()}</span>
                  </div>
                  <div
                    className="service-item-car-wrapper"
                    onClick={() => setCarToShowDetails(item.car)}
                  >
                    <Auto />
                    <span>{item.car.brand}</span>
                    <span>{item.car.model}</span>
                  </div>
                </div>
              </div>
              <div
                className="edit-mode-action-button-new"
                onClick={(event) => {
                    event.stopPropagation();
                  setShowActionButtons(index);
                }}
              >
                <div className="double-action">
                  <div>
                    <Trash />
                    <span>ODRZUĆ</span>
                  </div>
                  <div>
                    <Accept />
                    <span>AKCEPTUJ</span>
                  </div>
                </div>
                <div
                  className={`item-container-action-buttons-slider-new ${
                    showActionButtons === index ? "show" : ""
                  }`}
                >
                  <button
                    onClick={() => {
                      handleItemApproval(item._id);
                    }}
                  >
                    ODRZUĆ
                  </button>
                  <button
                    onClick={() => {
                      handleItemApproval(item._id, true);
                    }}
                  >
                    AKCEPTUJ
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="service-history-no-results">
            <label>brak aut oczekujących</label>
          </div>
        )}
      </div>
    </>
  );
}
